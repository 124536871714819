import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import banner from '../images/5_Products_banner.png';

import './ShimProducts.css';
import webAppThumbnail from '../images/web_app.jpg';

class ShimSolutions extends Component {
    render()
    {
      return (
        <Container fluid="md">
          <Row className="border">
            <img className="img-fluid w-100" src={banner} alt="Banner" />
          </Row>
          <Row>
            <Col><h2 className="mt-4">Web Application</h2> </Col>
          </Row>
          <Row>
            <Col className="col-3">
              <a href="https://arcg.is/Omrmj" target="_blank" rel="noreferrer">
                <img className="img-thumbnail" src={webAppThumbnail} alt="web app thumbnail"/>
               </a>
            </Col>
            <Col className="col-9">
           <p className="foo"><a href="https://arcg.is/Omrmj" target="_blank" rel="noreferrer">Flood Hazard + Housing and
           Practitioner Information Network for
           Florida Coastal Communities</a></p>
           <p> <em>This application is an outcome under
           the National Science Foundation-funded Smart and Connected
           Communities planning grant. The goal of the project is to create a
           community of practice that focuses on strengthening the technology
           and social/planning systems of communities in order to create a
           more resilient affordable housing system including pre-disaster
           mitigation and post-disaster recovery planning.</em>
           </p>
           </Col>
          </Row>
          <Row className="mt-3">
           <Col className="p-3">
           <h2>White Papers</h2>
           <p className="ShimCite">Kakade, S., Nalawala, Q., Srinivasan,
           R.S. <a href="/Drone-based-Building-Envelope-Energy-Performance-01142021.pdf">Drone-based Building Envelope Energy Performance</a> (2021)
           </p>
           <p className="ShimCite">Woo, J., Srinivasan, R.S. <a href="Blockchain_MRV_for_BEP.pdf">Applying
           Blockchain technology for building envelop energy performance measurement,
           reporting, and verification</a> (2021)
           </p>
           <p className="ShimCite">Xiang, Z. <a href="White_Paper_Draft_Xiang.pdf">Flood Hazard Management
           & Practitioner Information Network for Florida Coastal Communities</a> (2021)
           </p>

           <p className="ShimCite">Meiquing, F., Liu, R. <a href="Data_analysis_whitepaper_0203V2.pdf">
           Flood Hazard Management & Practitioner Information Network for
           Florida Coastal Communities</a> (2021)
           </p>

           <p className="ShimCite">Meiquing, F., Liu, R., Watkins, R. <a href="Literature_review_paper_draft_0203.pdf">Flood
           Hazard Management & Practitioner Information Network for Florida
           Coastal Communities</a> (2021)
           </p>

           <p className="ShimCite">Watkins, R. <a href="Inundation_App_Summary_NSF_final_20210203.pdf">Housing
           Flood Hazard Spatial Database and Mapping Application Summary</a> (2021)
           </p>


           </Col>
           </Row>
           <Row>
           <Col className="p-3">
           <h2>Conference Presentations</h2>
           <p className="ShimCite">Sourabh G. Kakade, Qusai M. Nalawala,
           Ravi S. Srinivasan. (2020). <a href="Kakade-et-al-2020-SENVAR.pdf ">“Preliminary
           Research in UAV-based Estimation of Lowest Floor Elevation for Flood
           Hazard Pre-Disaster Management,”</a> 20th International Conference on
           Sustainable Environment &amp; Architecture. <a href="http://senvar.event.upi.edu">http://senvar.event.upi.edu</a>
           </p>
           </Col>
          </Row>
          <Row>
            <Col className="p-3"><h2>Patents</h2>
            <p>Srinivasan, R.S., Nalawala, Q., Kakade, S. Drone-based Lowest Floor Elevation. [Provisional Patent], US Patent & Trademark Office, 2020</p>
            </Col>
          </Row>
        </Container>
    )}
}
export default ShimSolutions;
