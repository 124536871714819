import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import banner from '../images/4_Our_Progress_banner.png';

import './ShimProgress.css';

class ShimProgress extends Component {
    render()
    {
      return (
        <Container fluid="md">
          <Row className="border">
            <img className="img-fluid w-100" src={banner} alt="Banner" />
          </Row>
          <Row className="pt-3"><h2>Drone Test Flights</h2>
          </Row>
          <Row>
          <Col>
           <p>The need for flood hazard classification of building structures is
             critical for pre-disaster management and post-disaster mitigation.
             The importance cannot be understated especially with the rising sea
             levels and the threat of global warming. Among others, one of the important attributes is the Lowest Floor Elevation (LFE). We developed innovative drone-based LFE research including the development of novel methods to seamlessly gather, store, and extract relevant physical attributes for flood hazard management. Using the drone’s digital and infrared thermal (IRT) cameras, a set of pictures were captured at predefined positions in front of the building structures. These pictures were, then, processed by algorithms for estimating the LFE per
             FEMA. More information is available in Products page.
           </p>
           </Col>
          </Row>

          <Row>
            <h2>Practitioner Workshops</h2>
          </Row>
          <Row>
           <p><strong>Workshop #1.</strong> Through an <a href="/workshop">initial
           workshop in early 2021</a>, we explored the broader scope of data needs and potential
           desired technology to better assist communities in understanding the
           existing and future coastal flood risks that residential housing and
           affordable housing stock faces. The workshop focused on how to
           prioritize existing data gaps, determine data
           collection/standardization needs, as well as useful data sharing
           platforms/technologies.
           </p>
          <p><strong>Workshop #2.</strong> A second workshop in summer of 2021
          will determine how best to co-create a “Flood Hazard and Housing”
          community of practice, and how to better support cross- departmental
          and cross-jurisdictional knowledge sharing. The results of the
          workshop will provide insight into effective methods of sharing local
          communities’ knowledge, experiences, and best practices in
          pre-disaster mitigation planning and post-disaster recovery.
          </p>
          <h2>Community Consultations</h2>
          <p>The purpose of the community consultations is to introduce the NSF
          S&amp;CC project to potential stakeholders and engage collaborators
          prior to the larger workshops. The meetings are intended to be
          conversation-based and revolve around a few key questions between the
          participants and UF team.
          </p>
          <ul>
            <li><strong>Smita Ambadi</strong>, AICP, LEED AP, SCPM, Principal Planner, Pinellas County Department of Housing and Community Development</li>
            <li><strong>Libby Carnahan</strong>,       Sea Grant, Pinellas County Extension Agent</li>
            <li><strong>Ben Chandler</strong>, AICP, Disaster Resilience Coordinator, Apalachee Regional Planning Council</li>
            <li><strong>Gladys Cook</strong>, Director of Resilience and Disaster Recovery, Florida Housing Coalition</li>
            <li><strong>Lisa Foster</strong>, Pinellas County Floodplain Coordinator</li>
            <li><strong>Chelsea Hardy</strong>, Assistant County Attorney for Pinellas County</li>
            <li><strong>Hank Hodde</strong>, Pinellas County Sustainability and
            Resilience Coordinator</li>
            <li><strong>Evan Johnson</strong>,  AICP, LEED AP, Interim Program Director, Principal Planner, Pinellas County Department of Housing and Community Development</li>
            <li><strong>Brendan Mackesey</strong>, Assistant County Attorney for Pinellas County</li>
            <li><strong>Curt Nielsen</strong>, OCP, GISP, Deputy of GIS/Land Records for Pinellas County</li>
            <li><strong>CJ Reynolds</strong>, Director of Resilience and Engagement,
              Tampa Bay Regional Planning Council
            </li>
            <li><strong>Caroline Smith</strong>, Economic Development Planner, Apalachee Regional Planning Council</li>
            <li><strong>Rebecca Stonefield</strong>, CPM, Principal Planner, Pinellas County Department of Housing and Community Development</li>
            <li><strong>Noah Taylor</strong>, Floodplain Coordinator and CRS Coordinator, City of St. Petersburg</li>
            <li><strong>Mike Twitty</strong>, CFA, MIA, Pinellas County Property Appraiser</li>
            <li><strong>Jana Williams</strong>, Economic Recovery Coordinator/RLF Manager, Apalachee Regional Planning Council</li>
          </ul>
         </Row>
        </Container>
    )}
}
export default ShimProgress;
