import React, {Component} from 'react';
import { Container, Row} from 'react-bootstrap';
import './ShimTeam.css';
import Bio from './Bio.js';

import banner from '../images/2_Our_Team.png';

let joseText = "José A.B. Fortes is the AT&T Eminent Scholar and Professor of Electrical and "
+ "Computer Engineering at the University of Florida where he founded and is the "
+ "Director of the Advanced Computing and Information Systems Laboratory. Among other current projects, he is leading the development of the "
+ 'cyberinfrastructure of the NSF-funded <a href="https://www.idigbio.org/">iDigBio</a> project, '
+ 'is the principal investigator and Steering Committee Chair of the NSF-funded <a href="https://www.globalcentra.org/">CENTRA</a> initiative and is a principal or co- principal investigator '
+ 'of several other NSF projects (including <a href="http://humain.acis.ufl.edu/">HuMaIN</a> and '
+ '<a href="http://www.pragma-grid.net/">PRAGMA</a>).';

let andreaText = "Andrea Galinski’s research focuses on holistic, data-driven resilience planning. Her work brings together networks of floodplain managers, planners, and "
+ "housing program managers to advance equity-centered resilience planning. Her professional background includes climate adaptation planning, analysis, policy "
+ "development, and public outreach."

let doriText ="Dori Griffin’s design pedagogy and research centers around shifting the narrative "
+ "of graphic design education away from aesthetics and toward a historically "
+ "contextualized and critically informed praxis capable of solving important contemporary problems in a global context. Her recent publications engage "
+ "diverse issues such as cultural identity, cross-cultural communication, access to health care, and envisioning a more environmentally sustainable planetary future."

let ruiText ="Rui Liu’s research focuses on data mining, geospatial database development in "
+ "order to advance the construction industry with new technologies for a more sustainable and resilient world."

let billText ="Mr. O'Dell has spent several years at the Shimberg Center collaborating on the development of a number of data products, among them the Affordable Housing "
+ "Needs Assessments, Florida Housing Data Clearinghouse, and more."

let russText = "Russell Watkins’s work focuses on geospatial analysis of housing-related data. A"
+ 'recent project includes the “Housing and Coastal Flood Hazard Exposure" '
+ "web application, which illustrates the Assisted (Multifamily) Housing Inventory across the state of Florida in relation to several current and future coastal flood "
+ "hazards."

let erinText = "Erin Fowler's research interests focus on housing and community advocacy through a sustainable policy perspective. Her work focuses on eviction and "
+"foreclosure impacts related to the Covid-19 pandemic, as well as how community engagement and outreach can be incorporated into data-driven community "
+"development."

let harveyText ="Harvey Halprin is a third-year law student that has focused much of his studies on environmental and land use law. He has interned for the Pinellas County "
+ "Attorney’s Office and has worked on projects related to local government planning for sea level rise."

let sourabhText="Sourabh Kakade works in the UrbSys Lab developing methods to collect building "
+"attribute data using computer vision and machine learning from thermal images captured by drones equipped with an infrared thermal (IRT)"
+"camera in order to help mitigate flood risk in coastal communities of Florida."

let qusaiText="Qusai Nalawala is graduate student pursuing a master’s degree in thermal and fluid sciences at University of Florida. In the past year, he has worked on "
+"numerous renewable energy projects and is currently working to collect thermal data from buildings and other structures using unmanned aerial vehicles (UAVs) to "
+"evaluate building energy performance."

let samiraText=`Samira Shiridevich is an Iranian graphic designer who has a master’s in Visual
Communication from Tehran University of Art. Samira also practiced for nine
years as an art director in Avaye Sadra Resaneh Advertising Agency and Tehran
Municipality and as a graphic designer in some other companies. As one of her
recent academic articles, she presented “Visual Analysis of the Capital Cities of
Developed Countries’ Portals” at the ICOAH2018 conference. Samira also presented a panel at
Digitally Engaged Learning (DEL) Conference 2020. Lastly, she can talk in three languages and
enthusiastic about new cultures.
`

let xiangText="Xiang Zhou’s is graduate student pursuing a master’s degree in thermal and "
+"fluid sciences at University of Florida. His work focuses on the gathering, cleansing and visualization of geospatial data with Python."


let mintText ='<a href="https://arts.ufl.edu/academics/art-and-art-history/programs/design-and-visual-communications/mint/overview/"> Mint Design Studio </a>'
+ ' is a faculty-supervised, student graphic/communication design studio established in 2002 with the goal to provide advanced students the opportunity to apply design in real-world '
+ 'contexts. Mint responds to the changing field of design through interdisciplinary collaborations, partnering with people in their communities to co-design responses to mutually important issues and challenges. Mint covers the range of communication design activities, beginning with design research to understand'
+ 'context and opportunities, field research, UI/UX, data visualization, branding, workshops, strategy, and more. Since Mint is also a course, projects have a '
+ 'pedagogical function for students. <a href="mailto:hdesign@arts.ufl.edu">hdesing@arts.ufl.edu</a>'

let jimText = `Jim Martinez is a senior systems architect for the Shimberg
     Center for Housing Studies. His technical duties include software engineering,
     developing web applications, system administration, database administration and
     maintenance, and on occasion vacuuming. In his free time Mr. Martinez, who
     has earned both a Masters and a Bachelors degree in Mathematics, enjoys
     playing baseball, and salads. He is blessed with one wife, two sons and
     one original ACL.`

let meiqungText = `Meiqing is currently a Ph.D. Candidate in M. E. Rinker, Sr.
   School of Construction Management at University of Florida. His research
   focuses on the area of Building Information Modelling, virtual/augmented r
   eality, disaster and emergency management, and intelligent computing applied
   to building safety.`

let junghoonText = `Junghoon Woo is currently a Ph.D. Candidate in M. E. Rinker,
    Sr. School of Construction Management at the University of Florida. His
    research focuses on a new blockchain framework for existing building
    energy performance measurement, reporting, and verification (MRV), building
    carbon emission reduction and carbon credit, and machine learning for the
    construction management's productivity and efficiency. He is a member of
    Powell Center for Construction and Environment, and UrbSys Lab.`

// https://www.geeksforgeeks.org/how-to-wrap-text-around-circular-carousel-in-bootstrap-4/
class ShimTeam extends Component {
    render()
    {
      return (
        <Container fluid="md">
          <Row className="border">
            <img className="img-fluid w-100"  src={banner} alt="Banner" />
          </Row>
          <Row>
            <h2>About Our Team</h2>
          </Row>
          <Bio ShimName="(PI) Ravi Srinivasan, PhD"
               title="Associate Professor, UF M.E. Rinker School of Construction Management"
               text='Ravi Srinivasan’s research focuses on advanced modeling and simulation in the fields of building energy and sustainability. He is the Director of the <a href="http://urbsys.org/">UrbSys Lab</a> that explores urban building energy, sensing, controls, big data analysis, and visualization. Visit <a href="https://built-ecologist.com/">built-ecologist.com</a> for more information.'
               pic="ravi" />
           <Bio ShimName="Libby Carnahan"
              title="UF/IFAS Extension Pinellas County, Florida Sea Grant Agent"
              text="Libby Carnahan is the Florida Sea Grant Agent for UF/IFAS Extension Pinellas County. She holds her Climate Change Professional® (CC-P®) credential from the Association of Climate Change Officers. She is also founder and co-facilitator of the Tampa Bay Climate Science Advisory Panel, CSAP."
              pic="libby" />
          <Bio ShimName="José Fortes"
             title="Professor, UF Department of Electrical and Computer Engineering"
             text={joseText}
             pic="jose" />

         <Bio ShimName="Andrea Galinski"
            title="MLA, CFM, Assistant Scholar, UF Department of Landscape Architecture"
            text={andreaText}
            pic="andrea" />

         <Bio ShimName="Dori Griffin, PhD"
            title="Assistant Professor, School of Art + Art History"
            text={doriText}
            pic="dori" />

       <Bio ShimName="Rui Liu, PhD"
          title="Assistant Professor, UF M.E. Rinker School of Construction Management"
          text={ruiText}
          pic="rui" />

          <Bio ShimName="Jim Martinez, MA."
             title="Senior Systems Architect, UF Shimberg Center for Housing Studies"
             text={jimText}
             pic="jim" />


      <Bio ShimName="Bill O’Dell"
         title="Director, UF Shimberg Center for Housing Studies"
         text={billText}
         pic="bill" />
         <Bio ShimName="Russell Watkins, PhD"
            title="Geographer, UF Shimberg Center for Housing Studies"
            text={russText}
            pic="russ" />


      <hr />
      <h4 className="p-3">Graduate Students</h4>
      <Bio ShimName="Erin Fowler"
         title="MA Candidate, Urban and Regional Planning; Graduate Research Assistant, UF Shimberg Center for Housing Studies"
         text={erinText}
         pic="erin" />

         <Bio ShimName="Meiqing Fu"
            title="Ph.D. Candidate, M. E. Rinker, Sr. School of Construction Management"
            text={meiqungText}
            pic="meiqing" />

         <Bio ShimName="Harvey Halprin"
            title="J.D. Candidate; Student Associate, UF Levin College of Law Conservation Clinic"
            text={harveyText}
            pic="harvey" />

        <Bio ShimName="Sourabh Kakade"
           title="MS Candidate, Mechanical Engineering; Graduate Research Assistant, UrbSys Lab"
           text={sourabhText}
           pic="sourabh" />

       <Bio ShimName="Qusai Nalawala"
          title="MS Candidate, Mechanical Engineering: Thermal Sciences and Fluid Dynamics; Graduate Research Assistant, UrbSys Lab"
          text={qusaiText}
          pic="qusai" />

      <Bio ShimName="Samira Shiridevich"
           title=""
           text={samiraText}
           pic="samira" />

       <Bio ShimName="Junghoon Woo"
            title=""
            text={junghoonText}
            pic="junghoon" />

         <Bio ShimName="Xiang Zhou"
            title="MS Candidate, UF Computer and Information Science and Engineering; OPS Student Research Assistant"
            text={xiangText}
            pic="xiang" />

  <hr />
            <Bio ShimName="Mint Design Studio"
               title="UF School of Art + Art History"
               text={mintText}
               pic="mint" />

        </Container>
    )}
}
export default ShimTeam;
