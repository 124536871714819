import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import ravi from     '../images/headshots/Ravi.png';
import libby from    '../images/headshots/Libby.png';
import jose from     '../images/headshots/Jose.png';
import andrea from   '../images/headshots/Andrea.png';
import dori from     '../images/headshots/Dori.png';
import rui from      '../images/headshots/Rui.png';
import bill from     '../images/headshots/Bill.png';
import russ from     '../images/headshots/Russ.png';
import erin from     '../images/headshots/Erin.png';
import harvey from   '../images/headshots/Harvey.png';
import sourabh from  '../images/headshots/Sourabh.png';
import qusai from    '../images/headshots/Qusai.png';
import samira from   '../images/headshots/Samira.png';
import xiang from    '../images/headshots/Xiang.png';
import mint from     '../images/Logos/mint.jpeg';
import jim from      '../images/headshots/Jim.png';
import meiqing from  '../images/headshots/Meiqing.png';
import junghoon from '../images/headshots/Junghoon.png';

let imgObj ={
  "ravi": ravi,
  "libby":libby,
  "jose":jose,
  "andrea":andrea,
  "dori":dori,
  "rui":rui,
  "bill":bill,
  "russ":russ,
  "erin":erin,
  "harvey":harvey,
  "sourabh":sourabh,
  "qusai":qusai,
  "samira":samira,
  "xiang":xiang,
  "mint" : mint,
  "jim" : jim,
  "meiqing":meiqing,
  "junghoon":junghoon
}

class Bio extends Component {
    render()
    {
      let textObj = {__html: this.props.text};
      return (
  <Row className="p-3 align-items-center h-100">
    <Col className="col-2">
      <img className="ImgContainer" src={imgObj[this.props.pic]} alt="headshot"/>
    </Col>
    <Col className="col-10">
     <p className="align-middle"><strong>{this.props.ShimName}</strong>, {this.props.title} <br />
          <em><span dangerouslySetInnerHTML={ textObj }   /></em>
     </p>
    </Col>
  </Row>
    )}
}
export default Bio;
