import React, {Component} from 'react';
import { Container, Row, Col} from 'react-bootstrap';

import banner from './../images/graphics_banner_3_drone.png';
//import register_here from './../images/buttons/register.png';
//import ufLogo from '../images/Logos/UF_v2.png'
//import teamLogos from '../images/Logos/teams.png'
import teamLogos from '../images/workshop_logos_version_2.png';
import draftAgenda from '../images/workshop_agenda.png';

import './ShimWorkshop.css';

class ShimWorkshop extends Component {

    render()
    // to make image stretch use class="img-fluid w-100"
    // https://stackoverflow.com/questions/36933107/stretch-image-to-fit-full-container-width-bootstrap
    {
      return (
        <Container fluid="md">
        <Row className="border">
        <img className="img-fluid w-100" src={banner} alt="Banner" />
        </Row>
        <Row>
          <Col>
            <h2>Workshop #1: Local Government Data + Information Needs
            </h2>
            <p><em>Hosted by the University of Florida, UF IFAS Extension, and
            Florida Sea Grant as part of a National Science Foundation (NSF),
            Smart and Connected Communities Planning Grant
            </em></p>
            <p><strong>Time: </strong>February 5th, 10:00am-12:00pm<br/>
            <strong>Location: </strong>Via Zoom</p>
            <p>Check out the <a href="https://www.youtube.com/watch?v=N_nTdEdib7c">workshop
              recording</a>.
            </p>
            <p>
            <a href="/NSF_SCC_Workshop_slides.pdf">Powerpoint slides</a> are also available.
            </p>

            <h4>Description</h4>
            <p>
            This workshop will highlight ongoing resilience work in Pinellas
            County and the Tampa Bay area. It will also explore emergent and
            scalable technologies to better assist communities in understanding
            coastal flood hazards’ impact on residential housing stock with an
            emphasis on affordable housing. We welcome participants to share
            their thoughts on the critical issues involved in the mitigation of
            affordable housing and the needs of local communities. These
            suggestions and perspectives will assist in the development of a
            3-year, $1.5 million NSF implementation research grant.
            </p>

           <p>Here is the <a href="SCC-PG_Workshop_1_FinalAgenda.pdf" >workshop agenda</a>.</p>
             <a href="SCC-PG_Workshop_1_FinalAgenda.pdf" >
               <img className="shimAgenda" src={draftAgenda} alt="draft agenda" />
            </a>

           <p className="pt-3">Held in collaboration with: <br />
               <img src={teamLogos} style={{height:'100px'}} alt="Logos"/>
           </p>
          </Col>
        </Row>
        </Container>
    )}
}
export default ShimWorkshop;
