import React, {Component} from 'react';
import { Container, Row} from 'react-bootstrap';

import banner from '../images/7_Get_Involved_banner.png';

class ShimInvolved extends Component {
  render()
    { return(
      <Container fluid="md">
        <Row className="border">
          <img className="img-fluid w-100" src={banner} alt="Banner" />
        </Row>
        <Row>
          <h2>Get Involved!</h2>
          <p>This project proposes to co-produce a more comprehensive
            understanding of the challenges to creating more resilient
            affordable housing, as well as a process to develop solutions that
            are both effective and useful to the community. We seek to expand
            our initial team with other partners from local governments,
            research institutions, non-governmental agencies, community groups,
            or others that would be interested in this work.
          </p>
          <p>Our aim is to together enable the development and sharing of
            technical tools, data, and practitioner experiences that can lead
            to greater housing resilience. We hope you will join us!
          </p>
          <p>Want to stay in the know? <a href="https://forms.gle/yFGTUcW8o2RpjT6E9">Contact us!</a>
          </p>
        </Row>
      </Container>
    )}
}
export default ShimInvolved;
