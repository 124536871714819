import React, {Component} from 'react';
import { Container, Row, Col} from 'react-bootstrap';

import banner      from '../images/0_landing_page_final.png';

import about       from '../images/buttons/houses_below.png';
import ourTeam     from '../images/buttons/2_Our_Team_button.png';
import pilotProject from '../images/buttons/3_pilot_project.png'
import progress    from '../images/buttons/4_our_progress.png';
import products    from '../images/buttons/5_products.png';
import getInvolved from '../images/buttons/sky_line.png';

import ufLogo from '../images/Logos/UF_v2.png';
import nsfLogo from '../images/Logos/NSF.jpg';

// spacing
// https://getbootstrap.com/docs/4.0/utilities/spacing/

// card info is at:
//https://bit.dev/react-bootstrap/react-bootstrap/card

import './ShimLanding.css';

// <Col>
//   <div className={"row"} id={"box-search"}>
//     <div className="thumbnail text-center">
//       <img src={ourTeam} alt="" className="img-responsive" />
//       <div className="caption">
//        <h2 className="text-black">Solutions</h2>
//       </div>
//     </div>
//   </div>
// </Col>

// another idea
//https://bbbootstrap.com/snippets/ecommerce-single-product-card-open-detail-hover-49583085

// image with text over it
//https://gist.github.com/petehouston/85dd33210c0764eeae55

class ShimLanding extends Component {
  render()
    { return(
      <Container fluid="md">
        <Row className="border">
          <img className="img-fluid w-100" src={banner} alt="Banner" />
        </Row>
        <Row className="p-3">

          <h2 className="pt-3">Welcome to the “Flood Hazard &#43; Housing Practitioner Information
              Network” for Florida Coastal Communities
          </h2>
          <p>Through a recent National Science Foundation (NSF) Smart and
             Connected Communities (S&CC) grant, a team of UF researchers
             aims to collaborate with Florida communities to better understand
             housing stock at risk of coastal flooding. The goal of the project
             is to create a community of practice that focuses on strengthening
             the technology and social/planning systems of communities in order
             to create a more resilient affordable housing system including
             pre-disaster mitigation and post-disaster recovery planning.
          </p>
          <p>The focus of the initiative is initially on communities within the
            Tampa Bay Regional Planning Council and the Apalachee Regional
            Planning Council.
          </p>
        </Row>

        <Row className="p-3">

          <Col>
            <div className={"row"}>
              <a href="/about">
                <div className="thumbnail text-center hovereffect">
                  <img src={about} alt="" className="img-responsive" />
                  <div className="caption">
                   <h2 className="text-black overlay">About</h2>
                  </div>
                </div>
              </a>
            </div>
          </Col>


          <Col>
            <div className={"row"}>
             <a href="our-team">
              <div className="thumbnail text-center hovereffect">
                <img src={ourTeam} alt="" className="img-responsive" />
                <div className="caption">
                 <h2 className="text-black overlay">Our Team</h2>
                </div>
              </div>
             </a>
            </div>
          </Col>

          <Col>
            <div className={"row"}>
            <a href="/pilot-project">
              <div className="thumbnail text-center hovereffect">
                <img src={pilotProject} alt="" className="img-responsive" />
                <div className="caption">
                 <h2 className="text-black overlay">Pilot Project</h2>
                </div>
              </div>
             </a>
            </div>
          </Col>

        </Row>


        <Row className="p-3">

        <Col>
        <div className={"row"}>
         <a href="/progress">
          <div className="thumbnail text-center hovereffect">
            <img src={progress} alt="" className="img-responsive" />
            <div className="caption">
             <h2 className="text-black overlay">Our Progress</h2>
            </div>
          </div>
         </a>
        </div>
        </Col>

          <Col>
            <div className={"row"}>
             <a href="/products">
              <div className="thumbnail text-center hovereffect">
                <img src={products} alt="" className="img-responsive" />
                <div className="caption">
                 <h2 className="text-black overlay">Products</h2>
                </div>
              </div>
             </a>
            </div>
          </Col>

          <Col>
            <div className={"row"}>
              <a href="get-involved">
                <div className="thumbnail text-center hovereffect">
                  <img src={getInvolved} alt="" className="img-responsive" />
                  <div className="caption">
                    <h2 className="text-black overlay">Get Involved!</h2>
                  </div>
                </div>
              </a>
             </div>
           </Col>

        </Row>
        <Row >
          <div className="mx-auto">
            <img className="shimLogo p-2" src={ufLogo} alt="ufLogo"/>
            <img className="shimLogo p-2" src={nsfLogo} alt="uf logo" />
          </div>
       </Row>



     </Container>
    )}
}
export default ShimLanding;
