import React, {Component} from 'react';
import { Container, Row } from 'react-bootstrap';
import banner from './../images/3_Pilot_Project_banner.png';

import ImageExamples from '../images/IRT_image_examples_v2.png';

class ShimPilotProject extends Component {
    render()
    {
      return (
        <Container fluid="md">
          <Row className="border">
            <img className="img-fluid w-100" src={banner} alt="Banner" />
          </Row>
          <Row>
            <h2>Piloting New Technologies</h2>
          </Row>
          <Row><p>This project will push the boundaries of innovation to address
            existing information gaps.
          </p>
          <p>Existing methods of flood risk vulnerability assessment often rely
            on location and overlay methods. No account is taken of the
            elevation of the structures with respect to past or projected
            flood depth levels.
          </p>
          <p>The Shimberg team will pilot a new scalable method for deriving
            residential structures' lowest floor elevations (LFEs) in two pilot communities in Pinellas and
            Gulf Counties. To explore new ways of determining LFEs, the team
            will test deriving residential structure LFEs utilizing the
            collection of drone-based infrared imagery. LFEs can then be
            determined using machine learning and supercomputing
            processes on the <a href="https://www.rc.ufl.edu/services/hipergator/">University of Florida's
            HiPerGator 3.0</a>.
          </p>
          <p> Through a series of workshops and other community engagement
              activities, the Shimberg team will evaluate how the collection
              and use of such datasets can be most tangibly useful in Florida
              communities.
          </p>
          </Row>

          <Row>
            <img className="fluid w-100" src={ImageExamples} alt="example IRT from drone" />
          </Row>

        </Container>
    )}
}

export default ShimPilotProject;
