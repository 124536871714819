//import logo from './logo.svg';
//import './App.css';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ShimNav          from './components/ShimNav.js';
import ShimLanding      from './components/ShimLanding.js';

import ShimAbout        from './components/ShimAbout.js';
import ShimTeam         from './components/ShimTeam.js';
import ShimPilotProject from './components/ShimPilotProject.js';

import ShimProgress     from './components/ShimProgress.js';
import ShimProducts    from './components/ShimProducts.js';
import ShimInvolved     from './components/ShimInvolved.js';

import ShimWorkshop     from './components/ShimWorkshop.js';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  return (
    <Router>
    <div>
     <ShimNav />
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <ShimLanding />
          </Route>
          <Route exact path="/about">
            <ShimAbout />
          </Route>
          <Route path="/our-team">
            <ShimTeam />
          </Route>
          <Route path="/pilot-project">
            <ShimPilotProject />
          </Route>
          <Route path="/progress">
            <ShimProgress />
          </Route>
          <Route path="/products">
            <ShimProducts />
          </Route>
          <Route path="/get-involved">
            <ShimInvolved />
          </Route>
          <Route path="/workshop">
            <ShimWorkshop />
          </Route>
        </Switch>
      </div>
      </Router>
    );
  }
