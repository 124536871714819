import React, {Component} from 'react';
import { Container, Row, Col} from 'react-bootstrap';

//import banner from './../images/graphics_banner_4_notebook.png';
import banner from './../images/1_about_banner.png';
// 935 x 137

class ShimAbout extends Component {
    render()
    // to make image stretch use class="img-fluid w-100"
    // https://stackoverflow.com/questions/36933107/stretch-image-to-fit-full-container-width-bootstrap
    {
      return (
        <Container fluid="md">
        <Row className="border">
        <img className="img-fluid w-100" src={banner} alt="Banner" />
        </Row>
        <Row>
          <Col>
          <h2>About Our Planning Grant Project</h2>
          <p>
            With the help of the National Science Foundation (NSF) Smart and Connected Communities
            (S&CC) planning grant, a team of UF researchers is kickstarting a 1-year project to develop the
            technical tools to better understand the type and quantity of housing at risk of coastal flooding.
            Specifically, researchers want to better understand buildings’ lowest floor elevations (LFEs),
            which help identify more vulnerable locations, and to develop an integrated database to house
            elevation and other parcel-specific data. To start, the project will launch pilot projects in
            Pinellas and Gulf Counties, which we hope to next expand across the Tampa Bay and Apalachee regions.
          </p>
          <p>
            This NFS S&CC planning grant is the first step in the development of a larger 3-year, $1.5 million
            integrative research grant. The goal of the project is to contribute to more effective pre- and
            post-disaster management strategies by equipping decisionmakers with tools to assist the most
            vulnerable communities.
          </p>
          <p>
            The University of Florida invites local governments, universities,
            and other partners to join this initiative and in the collaboration
            for strategies to build “smarter and more connected” communities.
          </p>

          <div className="p-2"><a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=1951997&HistoricalAwards=false">
            NSF Award Number: 1951997</a>
          </div>
          <h2 className="pt-2">About the NSF’s Smart & Connected Communities Program</h2>
           <p className="ml-4">
            <em>The goal of the <a href="https://www.nsf.gov/funding/pgm_summ.jsp?pims_id=505364">NSF
            Smart and Connected Communities (S&amp;CC) program solicitation </a> is to
            accelerate the creation of the scientific and engineering foundations that will enable smart and
            connected communities to bring about new levels of economic opportunity and growth, safety
            and security, health and wellness, accessibility and inclusivity,
            and overall quality of life.
            </em>
          </p>
          <p className="ml-4">
           <em>
            The S&CC program supports integrative research that addresses fundamental technological and
            social science dimensions of smart and connected communities and pilots solutions together
            with communities.
           </em>
          </p>
          <p className="ml-4"> &#8212; Smart and Connected Communities (S&CC)
          Solicitation <a href="https://www.nsf.gov/publications/pub_summ.jsp?WT.z_pims_id=505364&ods_key=nsf21535">21-535</a>
          </p>
          <p>
            There is an urgent need for communities to be able to gain access to useful new data and
            information in the development of forward-thinking strategies for more resilient housing,
            particularly for resilient affordable housing. The University of Florida is eager to help tackle this
            challenge head-on with support from the National Science Foundation.
          </p>

          </Col>
        </Row>
        </Container>
    )}
}
export default ShimAbout;
