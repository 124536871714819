import React, {Component} from 'react';
import { Nav, Navbar, Container} from 'react-bootstrap';
//import logo from '../images/logo.png';
import logo from '../images/Logos/FH_HIN_logo_01.png';

// https://knowbody.github.io/react-router-docs/api/Link.html
// http://www.hackingwithreact.com/read/1/23/creating-a-link-between-pages-in-react-router
class ShimNav extends Component {
  // https://react-bootstrap.github.io/components/navbar/#navbars-colors
  render(){ return(
  <Container fluid="md">
    <Navbar collapseOnSelect expand="lg" variant="light">
      <Navbar.Brand>
        <img className="rounded" src={logo} alt="our logo" style={{width: 275}} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/our-team">Our Team</Nav.Link>
          <Nav.Link href="/pilot-project">Pilot Project</Nav.Link>
          <Nav.Link href="/progress">Our Progress</Nav.Link>
          <Nav.Link href="/products">Products</Nav.Link>
          <Nav.Link href="/get-involved">Get Involved!</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Container>
  )}
}

export default ShimNav;
